import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { CircleStrokeIcon, InProgressIcon, CheckmarkDoneIcon } from '@/assets/icons';

const AnalyticsPageTiles = ({ analyticsData }: any) => {
  const classNameTotal = useCallback(
    (index: number) =>
      cn('flex flex-grow flex-col p-6 gap-6 bg-white-light', {
        'border-l-2 border-white': index !== 0,
      }),
    [],
  );

  const totals = useMemo(
    () => [
      {
        title: 'Total requests',
        total: analyticsData?.total_number_of_requests,
        icon: <CircleStrokeIcon />,
        color: 'text-[#AD4F2B]',
      },
      {
        title: 'Active requests',
        total: analyticsData?.active_requests,
        icon: <InProgressIcon />,
        color: 'text-primary',
      },
      {
        title: 'Completed requests',
        total: analyticsData?.completed_requests,
        icon: <CheckmarkDoneIcon />,
        color: 'text-green',
      },
    ],
    [analyticsData],
  );

  return (
    <div role="presentation" className="flex flex-col gap-6 my-10">
      <div className="flex gap-0">
        {totals.map((item, index) => (
          <div key={item.title} className={classNameTotal(index)}>
            <div className="flex gap-3 items-center">
              <div className={`text-2xl leading-5 ${item.color}`}>{item.icon}</div>
              <div className="font-bold text-normal">{item.title}</div>
            </div>
            <div className="text-5xl font-light">{item.total}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnalyticsPageTiles;
