import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import { pluralize } from '@/common/utils';

export interface RemarkMessage {
  id: number;
  type: string;
  initials: string;
  full_name: string;
  body: string;
  created_at: string;
  is_current_user: boolean;
}

interface RemarksProps {
  remarks: RemarkMessage[];
  onSendMessage: (text: string) => void;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  message: string;
}
const Remarks: React.FC<RemarksProps> = ({ remarks, onSendMessage, setMessage, message }) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const prevRemarksState = useRef({ count: remarks.length, lastId: remarks.at(-1)?.id });

  useEffect(() => {
    const newLastId = remarks.at(-1)?.id;

    if (remarks.length > 3 && prevRemarksState.current.lastId && prevRemarksState.current.count) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    prevRemarksState.current = { count: remarks.length, lastId: newLastId };
  }, [remarks]);

  const handleSend = () => {
    if (message.trim()) onSendMessage(message);
  };

  return (
    <div className="relative bg-gray-50 border p-4 min-h-[500px]">
      <h2 className="text-lg font-semibold mb-4 pb-5 border-b border-gray-400">
        {`${remarks?.length} Remark${pluralize(remarks?.length)}`}
      </h2>
      <div className="relative space-y-6 max-h-[300px] overflow-y-auto">
        {remarks?.map((remark, index) => (
          <div key={remark.id} className="relative flex items-start mr-4">
            {index !== remarks.length - 1 && (
              <div className="absolute left-4 top-10 h-full w-[1px] bg-gray-300" />
            )}
            <div
              className={`font-semibold text-sm ${
                remark.is_current_user ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-800'
              } flex-shrink-0 h-8 w-8 bg-gray-400 text-white flex items-center justify-center rounded-full`}
            >
              {remark?.initials}
            </div>
            <div className="flex flex-col w-full ml-4">
              <div className="flex items-center space-x-1">
                <span className="font-semibold text-sm">{remark?.full_name}</span>
                {remark.is_current_user && <span className="text-xs text-gray-500">, you</span>}
              </div>
              <div
                className={`mt-1 px-4 py-2 rounded-lg rounded-tl-none text-sm ${
                  remark.is_current_user ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-800'
                }`}
              >
                {remark.body}
              </div>
              <span className="mt-1 text-xs text-gray-500 ml-auto">
                {dayjs(remark.created_at).format('D MMM YYYY, HH:mm')}
              </span>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="flex items-center border-t  absolute bottom-5 w-[95%] pt-4">
        <input
          type="text"
          className="flex-1 p-2 border rounded-md"
          placeholder="Type your message..."
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
        <button className="ml-2 px-4 py-2 text-blue-500" onClick={handleSend}>
          Send
        </button>
      </div>
    </div>
  );
};
export default Remarks;
