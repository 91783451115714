import { Button } from '@unbooking/ui-kit';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { GraduationCapIcon } from '@icons';
import { PATHS } from '@/common/path';

export const ExploreTrainingsNavButton: FC = () => {
  return (
    <NavLink to={PATHS.trainings}>
      <Button
        label="Explore our trainings"
        color="black"
        endIcon={<GraduationCapIcon />}
        outlined
      />
    </NavLink>
  );
};

export default ExploreTrainingsNavButton;
