import { InputTextarea } from '@unbooking/ui-kit';
import { FC } from 'react';
import { IEditComponent } from './types';

export const OperationalAreaEdit: FC<IEditComponent> = ({
  trainingRequest,
  updateTrainingRequest,
}) => {
  return (
    <div className="flex flex-col w-full gap-10">
      <div className="flex flex-col">
        <InputTextarea
          name="remarks"
          placeholder="Internal remarks"
          fullWidth
          light
          value={trainingRequest.remarks}
          onChange={e => updateTrainingRequest('remarks', e.target.value)}
        />
      </div>
    </div>
  );
};

export default OperationalAreaEdit;
