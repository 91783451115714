import { FC, useState } from 'react';

import { apiGet } from '@/common/axios';
import { downloadFile } from '@/common/utils';
import { handleError } from '@/common/handleError';
import { Button, ButtonProps } from '@unbooking/ui-kit';
import { AxiosError } from 'axios';
interface IDownloadButton extends ButtonProps {
  url: string;
  fileName?: string;
  onSuccess?: () => void;
}

export const DownloadButton: FC<IDownloadButton> = ({
  url,
  fileName = '',
  label = 'Download',
  onSuccess,
  ...buttonProps
}) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    try {
      const response = await apiGet(url, undefined, { responseType: 'blob' });
      if (response) {
        const contentDisposition = response.headers['content-disposition'];
        if (!fileName && contentDisposition) {
          // Guess file name based on Content-Disposition header
          fileName = contentDisposition.split('filename=')[1].replaceAll('"', '').trim() ?? '';
        }
        downloadFile(response.data as BlobPart, fileName);
        onSuccess?.();
      }
    } catch (error) {
      handleError(error as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  return <Button disabled={loading} onClick={handleDownload} label={label} {...buttonProps} />;
};

export default DownloadButton;
