import { AxiosResponse } from 'axios';
import { RequestTrainingFormSubmission } from '@/components/forms/RequestTrainingForm/common/interfaces';
import { apiGet, apiPost } from '../axios';
import { TrainingType } from '../enums';
import { IDropdownOption, ListResponse } from '../interfaces';
import { ISimpleAgency } from '@/common/api/agencies';
import { ISimpleCountry } from '@/common/api/countries';

export interface ITrainingProgram {
  id: string;
  title: string;
  training_type: TrainingType;
  description: string;
  short_description: string;
  position: number;
  background_image_url?: string;
  icon?: string;
}

export interface IRecoveryEquipment {
  id: string;
  label: string;
  equipment_type: string;
}

export interface ITrainingRequestContext {
  agencies: IDropdownOption[];
  countries: IDropdownOption[];
  recovery_equipment: IDropdownOption[];
  supervisor_roles: IDropdownOption[];
}

export interface ICreatedRequest {
  request_id: string;
}

export const getTrainingPrograms = () => {
  return apiGet<ListResponse<ITrainingProgram>>('/trainings/training-programs/');
};

export const getTrainingRequestContext = (): Promise<AxiosResponse<ITrainingRequestContext>> => {
  return apiGet<any>('/trainings/training-request-context/').then(response => {
    response.data = {
      ...response.data,
      agencies: response.data.agencies.map((agency: ISimpleAgency) => ({
        label: agency.short_name || agency.name,
        value: agency.id,
      })),
      countries: response.data.countries.map((country: ISimpleCountry) => ({
        label: country.name,
        value: country.id,
      })),
      recovery_equipment: response.data.recovery_equipment.map((equipment: IRecoveryEquipment) => ({
        label: equipment.label,
        value: equipment.id,
      })),
    };
    return response;
  });
};

export const postTrainingRequest = (form: RequestTrainingFormSubmission) => {
  return apiPost<ICreatedRequest>('/trainings/submit-training-request/', form);
};
