import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Header } from '@unbooking/ui-kit';
import { useAuth } from '@unbooking/ui-auth';
import {
  CalendarIcon,
  CalendarOptionIcon,
  CarbonChart,
  GraduationCapIcon,
  HomeIcon,
  ImpactIcon,
  InfoIcon,
  ListIcon,
  TrainerIcon,
} from '@icons';
import { WFPIcon } from '@/assets/img';
import { getDOPermissionData } from '@/common/api/digitalOffice';
import { PATHS } from '@/common/path';
import { useUser } from '@/common/hooks';
import { NavItem } from '@unbooking/ui-kit/lib/types';
import { useWafflesContext } from '../providers/WaffleProvider';

export type HeaderMenuType = 'default' | 'none' | 'digital-office' | 'user-menu';

const defaultNavItems: NavItem[] = [
  {
    name: 'Home',
    path: PATHS.home,
    icon: <HomeIcon />,
  },
  {
    name: 'Who we are',
    path: PATHS.whoAreWe,
    icon: <InfoIcon />,
  },
  {
    name: 'Global impact',
    path: PATHS.impact,
    icon: <ImpactIcon />,
  },
  {
    name: 'Our trainings',
    path: PATHS.trainings,
    icon: <GraduationCapIcon />,
  },
  {
    name: 'Our trainers',
    path: PATHS.instructors,
    icon: <TrainerIcon className="fill-current" />,
  },
  {
    name: 'Request a training',
    path: PATHS.requestTraining,
    icon: <CalendarOptionIcon />,
    className: 'bg-sky-500 rounded-sm',
  },
  // {
  //   name: 'Contact us',
  //   path: PATHS.contact,
  //   icon: <CalendarOptionIcon />,
  // },
];

const userMenuNavItems: NavItem[] = [
  {
    name: 'Home',
    path: PATHS.userProfile,
    icon: <HomeIcon />,
  },
  {
    name: 'My training requests',
    path: PATHS.userTrainingRequests,
    icon: <GraduationCapIcon />,
  },
];

interface IRoadSafetyHeader {
  menu?: HeaderMenuType;
}

export const RoadSafetyHeader: FC<IRoadSafetyHeader> = ({ menu = 'default' }) => {
  const { logout } = useAuth();
  const { flags } = useWafflesContext();

  const user = useUser();

  const { data: isDO } = useQuery({
    queryKey: ['digital-office-perms'],
    queryFn: async () => {
      const res = await getDOPermissionData();
      return res.is_do;
    },
  });

  const doLink = isDO ? '/digital-office' : '';
  const clientLink = flags.USER_PROFILE_PAGE.is_active ? '/profile' : undefined;

  const digitalOfficeNavItems: NavItem[] = [
    {
      name: 'Home',
      path: PATHS.digitalOffice,
      icon: <HomeIcon />,
    },
    {
      name: 'Requests list',
      path: PATHS.digitalOfficeTrainings,
      icon: <ListIcon />,
    },
    ...(flags.PLANNER_PAGE.is_active
      ? [
          {
            name: 'Planner',
            path: PATHS.digitalOfficePlanner,
            icon: <CalendarIcon />,
          },
        ]
      : []),
    ...(flags.ANALYTICS_PAGE.is_active
      ? [
          {
            name: 'Analytics',
            path: PATHS.digitalOfficeAnalytics,
            icon: <CarbonChart />,
          },
        ]
      : []),
  ];

  let navItems: NavItem[] = [];
  switch (menu) {
    case 'none':
      break;
    case 'digital-office':
      navItems = digitalOfficeNavItems;
      break;
    case 'user-menu':
      navItems = userMenuNavItems;
      break;
    default:
      navItems = defaultNavItems;
  }

  return (
    <Header
      username={user.full_name || user.email}
      navItems={navItems}
      logoImg={WFPIcon}
      logout={logout}
      lockLink={doLink}
      clientLink={clientLink}
      clientLinkText="My Trainings"
    />
  );
};

export default RoadSafetyHeader;
