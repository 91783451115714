import { FC } from 'react';

interface IUpload {
  label?: JSX.Element | string;
  onChange: (file: File) => void;
  id: string;
  accept?: string;
}

export const Upload: FC<IUpload> = ({ label = 'Upload', onChange, id, accept }) => {
  return (
    <>
      <label htmlFor={id} className="button button-primary">
        {label}
      </label>
      <input
        className="hidden"
        type="file"
        id={id}
        accept={accept}
        onChange={e => {
          const file = e.target.files?.[0];
          if (!file) return;
          onChange(file);
        }}
      />
    </>
  );
};

export default Upload;
