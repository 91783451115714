enum Environment {
  LOCAL = 1,
  DEV,
  QA,
  PROD,
}

const url = window.location.host;
const ENV = !url.includes('roadsafetyacademy.fleet.wfp.org')
  ? Environment.LOCAL
  : url.includes('dev')
    ? Environment.DEV
    : url.includes('qa')
      ? Environment.QA
      : Environment.PROD;

export { Environment, ENV };
