import dayjs from 'dayjs';
import { useCallback } from 'react';
import cn from 'classnames';
import CalendarDay from '../../../../interfaces/planner/CalendarDay';
import usePlannerDates from '../../../../hooks/planner/usePlannerDates';

type PlannerCalendarNumberProps = {
  day: CalendarDay;
};

const PlannerCalendarNumber = ({ day }: PlannerCalendarNumberProps) => {
  const { firstOfMonth } = usePlannerDates();

  const dateClassName = useCallback(
    (item: CalendarDay) =>
      cn('inline-flex gap-2 font-bold', {
        'text-gray-lighter':
          !item.date.isSame(dayjs()) && item.date.month() !== firstOfMonth?.month(),
        'text-white bg-red py-0 px-1 -mx-1': item.date
          .startOf('day')
          .isSame(dayjs().startOf('day')),
      }),
    [firstOfMonth],
  );

  return (
    <div className={dateClassName(day)}>
      <span>{day.date.date()}</span>
      {day.showMonth && <span> {day.date.format('MMMM')}</span>}
    </div>
  );
};

export default PlannerCalendarNumber;
