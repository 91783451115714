import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Table } from '@unbooking/ui-kit';
import { IStatus, SimpleTrainingRequest } from '@/common/api/digitalOffice';
import { StatusBlock, Tip } from '@components';
import { PATHS } from '@/common/path';
import { getAgencies, getEventDate, mapStatusToOption } from '../utils';

interface TableTrainingRequest {
  id: string;
  requestor: string;
  organization: string;
  trainingType: string;
  eventDates: string;
  attendees: string;
  status: string;
  statusToOption: Record<string, IStatus>;
}

const requestToTableRequest = (
  request: SimpleTrainingRequest,
  statusToOption: Record<string, IStatus>,
): TableTrainingRequest => {
  return {
    id: request.request_id,
    requestor: `${request.booked_by.first_name} ${request.booked_by.last_name}`,
    organization: getAgencies(request),
    trainingType: request.program,
    eventDates: getEventDate(request),
    attendees: request.num_of_attendees.toString(),
    status: request.status,
    statusToOption,
  };
};

const TABLE_COLUMNS = [
  {
    label: 'Training number',
    field: 'request_id',
    render: (row: TableTrainingRequest) => (
      <Link
        className="text-blue-400 font-medium"
        to={PATHS.digitalOfficeTrainingDetails.replace(':trainingRequestId', row.id)}
      >
        {row.id}
      </Link>
    ),
  },
  {
    label: 'Requestor',
    field: 'requestor',
  },
  {
    label: 'Organization',
    field: 'organization',
    nowrap: true,
    render: (row: TableTrainingRequest) => (
      <Tip
        className="inline-block max-w-[150px] overflow-hidden overflow-ellipsis"
        text={row.organization}
      >
        {row.organization}
      </Tip>
    ),
  },
  {
    label: 'Training program',
    field: 'trainingType',
    nowrap: true,
    render: (row: TableTrainingRequest) => (
      <Tip
        className="inline-block max-w-[150px] overflow-hidden overflow-ellipsis"
        text={row.trainingType}
      >
        {row.trainingType}
      </Tip>
    ),
  },
  {
    label: 'Proposed event dates',
    field: 'eventDates',
  },
  {
    label: 'Participants',
    field: 'attendees',
  },
  {
    label: 'Status',
    field: 'status',
    nowrap: true,
    render: (row: TableTrainingRequest) => (
      <StatusBlock status={row.status} statusToOption={row.statusToOption} />
    ),
  },
];

interface ITrainingRequestTable {
  trainingRequests: SimpleTrainingRequest[];
  statuses?: IStatus[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowLimit: number;
  setRowLimit: React.Dispatch<React.SetStateAction<number>>;
  resultsCount: number;
}

export const TrainingRequestTable: FC<ITrainingRequestTable> = ({
  trainingRequests,
  statuses = [],
  page,
  setPage,
  rowLimit,
  setRowLimit,
  resultsCount,
}) => {
  const tableTrainingRequests = useMemo(() => {
    const statusesOption = mapStatusToOption(statuses);
    return trainingRequests.map(request => requestToTableRequest(request, statusesOption));
  }, [trainingRequests, statuses]);

  const onOffsetChange = (offset: number) => {
    const page = Math.floor(offset / rowLimit) + 1;
    setPage(page);
  };

  return (
    <Table
      items={tableTrainingRequests}
      columns={TABLE_COLUMNS}
      count={resultsCount}
      limit={rowLimit}
      offset={rowLimit * (page - 1)}
      onChangeLimit={setRowLimit}
      onChangeOffset={onOffsetChange}
      light
    />
  );
};

export default TrainingRequestTable;
