/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { MapMouseEvent, MapRef } from 'react-map-gl';
import turf from '@turf/centroid';
import { AllGeoJSON } from '@turf/helpers';
import { Feature } from './ImpactMap';

export interface IDetailsItem {
  altContent?: JSX.Element;
  className?: string;
  content: string | JSX.Element;
  label: string;
}

const mapboxStyle = 'mapbox://styles/ie3yit/cjok5bbmd0ach2spkmxoeaziz';

const onDropdownHandler = (countryId: string, features: Feature[], mapRef: MapRef) => {
  if (!countryId) {
    unzoomFromCountry(mapRef);
    return;
  }
  const selectedFeature = features.find(feature => feature.properties.id === countryId);
  if (!selectedFeature) return;
  zoomToCountry(selectedFeature, mapRef);
};

const zoomToCountry = (countryFeature: Feature, mapRef: MapRef) => {
  if (!countryFeature.geometry) return;
  const centroid = turf(countryFeature.geometry as AllGeoJSON).geometry.coordinates;

  mapRef.getMap().flyTo({
    zoom: 4,
    speed: 0.45,
    center: [centroid[0], centroid[1]],
    pitch: 25,
    padding: { right: 0, left: 150, top: 0, bottom: 0 },
  });
};

const unzoomFromCountry = (mapRef: MapRef) => {
  const map = mapRef.getMap();
  map.doubleClickZoom.enable();
  map.keyboard.enable();

  map.flyTo({ zoom: 2.5 });
  map.removeFeatureState({ source: 'grouped-countries' });
};

const onMouseHandler = (
  event: MapMouseEvent,
  selectedCountry: string | null,
  hoveredCountry: string | null,
  setHoveredCountry: React.Dispatch<React.SetStateAction<string | null>>,
) => {
  if (event.features) {
    const featureId = event.features[0]?.id;

    if (!featureId || selectedCountry) {
      return (event.target.getCanvas().style.cursor = 'default');
    }

    if (hoveredCountry && featureId !== hoveredCountry) {
      event.target.removeFeatureState({ source: 'grouped-countries', id: hoveredCountry }, 'hover');
    }

    setHoveredCountry(featureId as string);
    event.target.getCanvas().style.cursor = 'pointer';
    event.target.setFeatureState({ source: 'grouped-countries', id: featureId }, { hover: true });
  }
};

const highlightedCountriesBorderLayer: any = {
  id: 'geojson-layer-borders',
  type: 'line' as 'sky',
  beforeId: 'country-label',
  source: 'grouped-countries',
};

const highlightedCountriesLayer: any = {
  id: 'grouped-geojson-layer-fill',
  type: 'fill' as 'sky',
  beforeId: 'country-label',
  paint: {
    'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#579dce', '#096eb4'],
  },
};

export {
  unzoomFromCountry,
  onDropdownHandler,
  onMouseHandler,
  mapboxStyle,
  highlightedCountriesBorderLayer,
  highlightedCountriesLayer,
};
