import { generateLargeColorPalette } from './utils';

type DataItem = {
  label: string;
  count: number;
};

type ProgressBarChartProps = {
  data: DataItem[];
  title?: string;
  className?: string;
  titleClassName?: string;
  rowContainerClassName?: string;
};

export const ProgressBarChart = ({ data, title }: ProgressBarChartProps) => {
  const maxCount = data[0]?.count || 0;
  const totalCount = data.reduce((sum, item) => sum + item.count, 0);

  return (
    <div className="space-y-4">
      {title && <span className="font-bold text-lg">{title}</span>}
      <div className="space-y-2 max-h-[250px] overflow-y-auto pr-4">
        {data.map(({ label, count }, index) => {
          const backgroundColor = generateLargeColorPalette('#004981', data.length);
          const widthPercentage = maxCount > 0 ? ((count / maxCount) * 100) / 1.4 : 0.5;
          const itemPercentage = totalCount > 0 ? ((count / totalCount) * 100).toFixed(2) : '0.00';

          return (
            <div key={index} className="flex items-center space-x-4">
              <span className="w-1/2 font-normal">{label}</span>
              <div className="flex-grow h-6 relative">
                <div
                  className="h-6 rounded-tr-lg rounded-br-lg"
                  style={{ width: `${widthPercentage}%`, backgroundColor: backgroundColor[index] }}
                />
                <span className="text-sm  font-light text-gray absolute right-0 top-1/2 mr-14 -translate-y-1/2">
                  {count}
                </span>
                <span className="text-sm  font-light text-gray absolute right-0 top-1/2 -translate-y-1/2">
                  ({itemPercentage}%)
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
