import { FC, useState } from 'react';
import { Tabs } from '@unbooking/ui-kit';
import {
  AgencyIcon,
  AttendeesIcon,
  CommunicationIcon,
  LocationIcon,
  TrainerIcon,
  VehicleIcon,
} from '@icons';
import {
  getAgencyData,
  getAttendeesData,
  getCommunicationsData,
  getOperationalAreaData,
  getTimeAndLocationData,
  getTrainerData,
  getVehiclesData,
} from '../utils';
import { EditTrainingSectionModal } from '../../edit';
import { IEditSection } from '../../edit/types';
import { IconWrapper } from '@/components/common';
import { useWafflesContext } from '@/app/providers/WaffleProvider';
import { IStatus, TrainingRequestDetails } from '@/common/api/digitalOffice';
import { ParticipantsTab, RequestDetailsTab, RequestOverviewTab, HistoryTab } from '.';

enum Tab {
  RequestsOverview = 'requests_overview',
  Details = 'details',
  Participants = 'participants',
  History = 'history',
}

interface IRequestDetails {
  trainingRequest: TrainingRequestDetails;
  statuses: IStatus[];
}

const RequestDetails: FC<IRequestDetails> = ({ trainingRequest, statuses }) => {
  const { flags } = useWafflesContext();

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editSection, setEditSection] = useState<IEditSection>();
  const [activeTab, setActiveTab] = useState<string>(Tab.RequestsOverview);

  const sections: IEditSection[] = [
    {
      title: 'Agency',
      icon: (
        <IconWrapper>
          <AgencyIcon size="30" />
        </IconWrapper>
      ),
      data: getAgencyData(trainingRequest),
      sectionType: 'agency',
    },
    {
      title: 'Time and location',

      icon: (
        <IconWrapper>
          <LocationIcon highlight size="30" />
        </IconWrapper>
      ),
      data: getTimeAndLocationData(trainingRequest),
      sectionType: 'time-and-location',
    },
    {
      title: 'Participants',
      icon: (
        <IconWrapper>
          <AttendeesIcon size="30" />
        </IconWrapper>
      ),
      data: getAttendeesData(trainingRequest),
      sectionType: 'participants',
    },
    {
      title: 'Communications',
      icon: (
        <IconWrapper>
          <CommunicationIcon size="30" />
        </IconWrapper>
      ),
      data: getCommunicationsData(trainingRequest),
      sectionType: 'communications',
    },
    {
      title: 'Vehicles',
      icon: (
        <IconWrapper>
          <VehicleIcon size="30" />
        </IconWrapper>
      ),
      data: getVehiclesData(trainingRequest),
      sectionType: 'vehicles',
    },
    {
      title: 'Travel requirements for trainers',
      icon: (
        <IconWrapper>
          <TrainerIcon size="30" />
        </IconWrapper>
      ),
      data: getTrainerData(trainingRequest),
      sectionType: 'trainers',
    },
    // ...((flags.REMARKS_FIELD.is_active
    //   ? [
    //       {
    //         title: 'Operational area',
    //         icon: (
    //           <IconWrapper>
    //             <CommunicationIcon size="30" />
    //           </IconWrapper>
    //         ),
    //         data: getOperationalAreaData(trainingRequest),
    //         sectionType: 'operational-area',
    //       },
    //     ]
    //   : []) as IEditSection[]),
  ];

  return (
    <div className="mx-5">
      <Tabs
        active={activeTab}
        setActive={setActiveTab}
        light
        tabs={[
          {
            id: Tab.RequestsOverview,
            title: 'Request overview',
            Component: <RequestOverviewTab trainingId={trainingRequest?.id} />,
          },
          {
            id: Tab.Details,
            title: 'Request details',
            Component: (
              <RequestDetailsTab
                sections={sections}
                setEditSection={setEditSection}
                setEditModalOpen={setEditModalOpen}
              />
            ),
          },
          ...(flags.PARTICIPANTS_TAB.is_active
            ? [
                {
                  id: Tab.Participants,
                  title: 'Participants',
                  Component: <ParticipantsTab trainingRequest={trainingRequest} />,
                },
              ]
            : []),
          ...(flags.HISTORY_TAB.is_active
            ? [
                {
                  id: Tab.History,
                  title: 'History',
                  Component: <HistoryTab trainingRequest={trainingRequest} statuses={statuses} />,
                },
              ]
            : []),
        ]}
      />
      <EditTrainingSectionModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        section={editSection}
        trainingRequest={trainingRequest}
      />
    </div>
  );
};

export default RequestDetails;
