import React, { FC, useMemo, useRef, useState } from 'react';
import ReactMapGl, {
  Source,
  Layer,
  MapboxGeoJSONFeature,
  MapRef,
  NavigationControl,
  MapLayerMouseEvent,
} from 'react-map-gl';
import { useQuery } from '@tanstack/react-query';
import { MapMouseEvent } from 'mapbox-gl';
import {
  onMouseHandler,
  mapboxStyle,
  highlightedCountriesBorderLayer,
  highlightedCountriesLayer,
  unzoomFromCountry,
  onDropdownHandler,
} from './utils';
import 'mapbox-gl/dist/mapbox-gl.css';
import {
  getCountryTrainings,
  getGeoJson,
  ICountryTrainings,
  IGlobalTrainingStats,
} from '@/common/api/impact';
import CountrySelect, { ICountrySelectStats } from './CountrySelect';

export interface Feature {
  geometry: any;
  properties: {
    id: string;
    area: number;
    capital: string;
    name: string;
  };
  id: string | number;
  type: string;
}

const mapKey = import.meta.env.REACT_APP_MAP_KEY;

const ImpactMap: FC = () => {
  // across this component, country code is used to identify country, to simplify matching with
  // countries from map API
  const [selectedCountryCode, setSelectedCountryCode] = React.useState<string | null>(null);
  const [hoveredCountry, setHoveredCountry] = React.useState<string | null>(null);
  const [features, setFeatures] = React.useState<Feature[]>([]);
  const [countryList, setCountryList] = React.useState<ICountryTrainings[]>([]);
  const [globalStats, setGlobalStats] = React.useState<IGlobalTrainingStats>();
  const ref = useRef(null);
  const mapRef = ref.current as unknown as MapRef;

  const [viewport, setViewport] = useState({
    zoom: 2.6,
    longitude: 20.68674593817775,
    latitude: 7.7439240888921,
  });

  const availableCountries = useMemo(() => {
    return countryList.map(country => country.code);
  }, [countryList]);

  const selectedCountry = useMemo(() => {
    return countryList.find(country => country.code === selectedCountryCode);
  }, [selectedCountryCode, countryList]);

  const trainingsStats = useMemo<ICountrySelectStats>(() => {
    const showGlobalStats = selectedCountry === undefined;
    const dataSource = showGlobalStats ? globalStats : selectedCountry;
    return {
      numOfDrivers: dataSource?.num_of_drivers || 0,
      numOfTrainings: dataSource?.num_of_trainings || 0,
      numOfCountries: dataSource?.num_of_countries || 0,
    };
  }, [selectedCountry, globalStats]);

  useQuery({
    queryKey: [`country-training-list`],
    queryFn: async () => {
      const data = await getCountryTrainings();
      setCountryList(data.countries);
      setGlobalStats({ ...data.global_stats, num_of_countries: data.countries.length });
      return data;
    },
  });

  useQuery({
    queryKey: ['geo-json'],
    queryFn: async () => {
      const data = await getGeoJson();
      const mappedData = data.map((item, idx) => ({ ...item, id: idx })); // Note: need number type of ID
      setFeatures(mappedData);
      return data;
    },
  });

  const onMouseLeaveHandler = (event: MapMouseEvent) => {
    hoveredCountry &&
      event.target.removeFeatureState({ source: 'grouped-countries', id: hoveredCountry }, 'hover');
    setHoveredCountry(null);
  };

  const filteredCountries = {
    type: 'FeatureCollection',
    features: features.filter((feature: Feature) =>
      availableCountries.includes(feature?.properties.id),
    ),
  };

  const onMapClickHandler = (e: MapLayerMouseEvent) => {
    const selectedFeature = e.features?.length ? e.features[0] : null;
    if (!selectedCountryCode && selectedFeature && selectedFeature.properties) {
      onCountrySelect(selectedFeature.properties.id);
    } else {
      setSelectedCountryCode(null);
      unzoomFromCountry(mapRef);
    }
  };

  const onCountrySelect = (countryCode: string) => {
    setSelectedCountryCode(countryCode);
    onDropdownHandler(countryCode, features, mapRef);
  };

  return (
    <div className="map-container">
      <CountrySelect
        selectedCountry={selectedCountryCode}
        onCountrySelect={onCountrySelect}
        countryList={countryList}
        stats={trainingsStats}
      />
      <ReactMapGl
        attributionControl={false}
        initialViewState={viewport}
        style={{ height: '91vh', width: '100%' }}
        mapStyle={mapboxStyle}
        minZoom={2}
        maxZoom={6}
        mapboxAccessToken={mapKey}
        doubleClickZoom={false}
        onMouseMove={e => onMouseHandler(e, selectedCountryCode, hoveredCountry, setHoveredCountry)}
        onMouseLeave={event => !selectedCountryCode && onMouseLeaveHandler(event)}
        onClick={onMapClickHandler}
        interactiveLayerIds={['geojson-layer-borders', 'grouped-geojson-layer-fill']}
        onMove={evt => {
          setViewport(evt.viewState);
        }}
        ref={ref}
      >
        <NavigationControl />
        <Source
          id="grouped-countries"
          type="geojson"
          data={filteredCountries as unknown as MapboxGeoJSONFeature}
        >
          <Layer {...highlightedCountriesLayer} />
          <Layer {...highlightedCountriesBorderLayer} />
        </Source>
      </ReactMapGl>
    </div>
  );
};

export default ImpactMap;
