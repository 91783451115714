import { FC } from 'react';

interface SkeletonProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  className?: string;
}

export const Skeleton: FC<SkeletonProps> = ({
  width = '100%',
  height = '16px',
  borderRadius = '4px',
}) => (
  <div
    className={'overflow-hidden relative animate-pulse-wave'}
    style={{
      width,
      height,
      borderRadius,
      backgroundImage: 'linear-gradient(90deg, #e5e7eb 25%, #f3f4f6 50%, #e5e7eb 75%)',
      backgroundSize: '200% 100%',
      backgroundRepeat: 'no-repeat',
    }}
  />
);
