import { useState } from 'react';
import Remarks from '../Remarks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getDORequestOverview, addDORequestOverviewRemark } from '@/common/api/digitalOffice';
import { Spinner } from '@/components/common';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { handleError } from '@/common/handleError';
import { useParams } from 'react-router';

const RequestOverviewTab = ({ trainingId }: { trainingId: string }) => {
  const queryClient = useQueryClient();
  const { trainingRequestId } = useParams() || {};
  const [message, setMessage] = useState('');

  const { data: overviewData, isFetching } = useQuery({
    queryKey: ['request-overview'],
    queryFn: async () => {
      const { results } = await getDORequestOverview(trainingId);

      return results;
    },
    gcTime: 0,
  });

  const updateStatusMutation = useMutation({
    mutationFn: addDORequestOverviewRemark,
  });

  const handleSendMessage = async (message: string) => {
    try {
      await updateStatusMutation.mutateAsync({
        requestId: trainingRequestId ?? '',
        body: message,
      });

      toast.success('Message successfully added');
      setMessage('');
      await queryClient.invalidateQueries({ queryKey: ['request-overview'] });
    } catch (error) {
      console.error(error as AxiosError);

    }
  };

  return (
    <section className="bg-catskill-white p-2">
      {isFetching && <Spinner fullScreen />}
      <div className="bg-white-light w-[50%]">
        <Remarks
          remarks={overviewData ?? []}
          onSendMessage={handleSendMessage}
          setMessage={setMessage}
          message={message}
        />
      </div>
    </section>
  );
};

export default RequestOverviewTab;
