import { FC } from 'react';
import PageDescription from '../../components/cms/PageDescription';
import { IPageWithContentComponent } from '@/common/interfaces';
import BookTrainingNavButton from '../../components/buttons/BookTrainingNavButton';
import ExploreTrainingsNavButton from '../../components/buttons/ExploreTrainingsNavButton';
import { getBgImageStyle } from '@/common/styles';

export const HomePage: FC<IPageWithContentComponent> = ({ content }) => {
  return (
    <div
      style={getBgImageStyle(content.background_image_url)}
      className="flex flex-1 bg-no-repeat bg-cover"
    >
      <div className="flex flex-1 overflow-y-auto bg-gradient-to-r from-black to-transparent">
        <div
          className="container w-full h-max justify-start min-h-full flex items-center py-10"
          data-testid="home-container"
        >
          <div className="lg:basis-1/2 flex flex-col gap-5 bg-white p-8">
            <PageDescription
              pageContent={content}
              titleClassname="font-bold"
              paragraphClassname="font-normal"
            />
            <div className="flex flex-row gap-3">
              <ExploreTrainingsNavButton />
              <BookTrainingNavButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
