export const EventsIcon = () => (
  <svg
    className="inline-block"
    width="1em"
    height="1em"
    viewBox="0 0 20 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_602_29247)">
      <path
        d="M16.25 9.25H15V10.5H16.25C16.7471 10.5005 17.2237 10.6983 17.5752 11.0498C17.9267 11.4013 18.1245 11.8779 18.125 12.375V14.875H19.375V12.375C19.374 11.5465 19.0445 10.7522 18.4586 10.1664C17.8728 9.58051 17.0785 9.25096 16.25 9.25Z"
        fill="currentColor"
      />
      <path
        d="M15 3C15.3708 3 15.7334 3.10997 16.0417 3.31599C16.35 3.52202 16.5904 3.81486 16.7323 4.15747C16.8742 4.50008 16.9113 4.87708 16.839 5.24079C16.7666 5.60451 16.5881 5.9386 16.3258 6.20083C16.0636 6.46305 15.7295 6.64163 15.3658 6.71397C15.0021 6.78632 14.6251 6.74919 14.2825 6.60727C13.9399 6.46536 13.647 6.22504 13.441 5.91669C13.235 5.60835 13.125 5.24584 13.125 4.875C13.125 4.37772 13.3225 3.90081 13.6742 3.54917C14.0258 3.19754 14.5027 3 15 3ZM15 1.75C14.3819 1.75 13.7777 1.93328 13.2638 2.27666C12.7499 2.62004 12.3494 3.1081 12.1129 3.67911C11.8764 4.25013 11.8145 4.87847 11.935 5.48466C12.0556 6.09085 12.3533 6.64767 12.7903 7.08471C13.2273 7.52175 13.7842 7.81938 14.3903 7.93995C14.9965 8.06053 15.6249 7.99865 16.1959 7.76212C16.7669 7.5256 17.255 7.12506 17.5983 6.61116C17.9417 6.09725 18.125 5.49307 18.125 4.875C18.125 4.0462 17.7958 3.25134 17.2097 2.66529C16.6237 2.07924 15.8288 1.75 15 1.75Z"
        fill="currentColor"
      />
      <path
        d="M14.375 19.25H13.125V18C13.1245 17.5029 12.9267 17.0263 12.5752 16.6748C12.2237 16.3233 11.7471 16.1255 11.25 16.125H8.75C8.25289 16.1255 7.77629 16.3233 7.42478 16.6748C7.07327 17.0263 6.87555 17.5029 6.875 18V19.25H5.625V18C5.62598 17.1715 5.95553 16.3772 6.54137 15.7914C7.12721 15.2055 7.9215 14.876 8.75 14.875H11.25C12.0785 14.876 12.8728 15.2055 13.4586 15.7914C14.0445 16.3772 14.374 17.1715 14.375 18V19.25Z"
        fill="currentColor"
      />
      <path
        d="M10 8.625C10.3708 8.625 10.7334 8.73497 11.0417 8.94099C11.35 9.14702 11.5904 9.43986 11.7323 9.78247C11.8742 10.1251 11.9113 10.5021 11.839 10.8658C11.7666 11.2295 11.5881 11.5636 11.3258 11.8258C11.0636 12.088 10.7295 12.2666 10.3658 12.339C10.0021 12.4113 9.62508 12.3742 9.28247 12.2323C8.93986 12.0904 8.64702 11.85 8.441 11.5417C8.23497 11.2334 8.125 10.8708 8.125 10.5C8.125 10.0027 8.32255 9.52581 8.67418 9.17417C9.02581 8.82254 9.50272 8.625 10 8.625ZM10 7.375C9.38194 7.375 8.77775 7.55828 8.26384 7.90166C7.74994 8.24504 7.3494 8.7331 7.11288 9.30411C6.87635 9.87513 6.81447 10.5035 6.93505 11.1097C7.05563 11.7158 7.35325 12.2727 7.79029 12.7097C8.22733 13.1467 8.78415 13.4444 9.39034 13.565C9.99654 13.6855 10.6249 13.6236 11.1959 13.3871C11.7669 13.1506 12.255 12.7501 12.5983 12.2362C12.9417 11.7223 13.125 11.1181 13.125 10.5C13.125 9.6712 12.7958 8.87634 12.2097 8.29029C11.6237 7.70424 10.8288 7.375 10 7.375Z"
        fill="currentColor"
      />
      <path
        d="M5 9.25H3.75C2.9215 9.25098 2.12721 9.58053 1.54137 10.1664C0.955529 10.7522 0.625976 11.5465 0.625 12.375V14.875H1.875V12.375C1.87555 11.8779 2.07327 11.4013 2.42478 11.0498C2.77629 10.6983 3.25289 10.5005 3.75 10.5H5V9.25Z"
        fill="currentColor"
      />
      <path
        d="M5 3C5.37084 3 5.73335 3.10997 6.0417 3.31599C6.35004 3.52202 6.59036 3.81486 6.73228 4.15747C6.87419 4.50008 6.91132 4.87708 6.83897 5.24079C6.76663 5.60451 6.58805 5.9386 6.32583 6.20083C6.0636 6.46305 5.72951 6.64163 5.3658 6.71397C5.00208 6.78632 4.62508 6.74919 4.28247 6.60727C3.93986 6.46536 3.64702 6.22504 3.441 5.91669C3.23497 5.60835 3.125 5.24584 3.125 4.875C3.125 4.37772 3.32255 3.90081 3.67418 3.54917C4.02581 3.19754 4.50272 3 5 3ZM5 1.75C4.38193 1.75 3.77775 1.93328 3.26384 2.27666C2.74994 2.62004 2.3494 3.1081 2.11288 3.67911C1.87635 4.25013 1.81447 4.87847 1.93505 5.48466C2.05563 6.09085 2.35325 6.64767 2.79029 7.08471C3.22733 7.52175 3.78415 7.81938 4.39034 7.93995C4.99654 8.06053 5.62487 7.99865 6.19589 7.76212C6.76691 7.5256 7.25496 7.12506 7.59834 6.61116C7.94172 6.09725 8.125 5.49307 8.125 4.875C8.125 4.0462 7.79576 3.25134 7.20971 2.66529C6.62366 2.07924 5.8288 1.75 5 1.75Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_602_29247">
        <rect width="20" height="20" fill="currentColor" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default EventsIcon;
