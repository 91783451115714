import { capitalize } from '@/common/utils';
import { IDropdownOption } from '@/common/interfaces';
import {
  IAgencyStepData,
  IAttendeesStepData,
  ICommunicationsStepData,
  IProgramStepData,
  ITimeAndLocationStepData,
  ITrainerStepData,
  IVehiclesStepData,
} from '../common/interfaces';

export const yesNoLabel = (value: boolean | null, nullLabel = 'N/A') =>
  value ? 'Yes' : value === false ? 'No' : nullLabel;

export const addOptionalLine = (label: string, value: string | null | undefined) => {
  return value ? [{ label, value }] : [];
};

export function formatDateRange(startDateStr: string, endDateStr: string) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };

  const startLabel = startDate.toLocaleDateString('en-US', options);
  const endLabel = endDate.toLocaleDateString('en-US', options);

  if (startLabel === endLabel) {
    return startLabel;
  } else {
    return `${startLabel} - ${endLabel}`;
  }
}

export const getProgramData = (data: IProgramStepData) => {
  return [{ label: 'Training program', value: data.program?.title || 'N/A' }];
};

export const getAgencyData = (
  agencyFormData: IAgencyStepData,
  agenciesOptions: IDropdownOption[],
) => {
  const agencyNames =
    agenciesOptions
      .filter(({ value }) => agencyFormData.agencies.includes(value))
      .map(({ label }) => label) ?? [];
  if (agencyFormData.other_agencies) {
    agencyNames.push(agencyFormData.other_agencies);
  }
  return [
    {
      label: 'Selected agencies',
      value: agencyNames.join(', '),
    },
  ];
};

export const getTimeAndLocationData = (
  data: ITimeAndLocationStepData,
  countries: IDropdownOption[],
) => {
  const country = countries.find(({ value }) => value === data.country_location);
  const countryName = country ? country.label : 'N/A';
  return [
    {
      label: 'Preferred Period',
      value: formatDateRange(data.start_date, data.end_date),
    },
    ...addOptionalLine('Reason', data.reason),
    {
      label: 'Day of the week training should NOT take place',
      value: capitalize(data.weekend_training_days?.toLowerCase()) ?? 'N/A',
    },
    {
      label: 'Preferred country',
      value: countryName,
    },
    {
      label: "Preferred 'in country' location(s)",
      value: data.preferred_place,
    },
    // {
    //   label: 'Functional classroom / projector / IT available?',
    //   value: yesNoLabel(data.projector_available),
    // },
    // {
    //   label: '30 traffic cones available?',
    //   value: yesNoLabel(data.cones_available),
    // },
    ...addOptionalLine('Working hours', data.co_working_hours),
  ];
};

export const getVehiclesData = (data: IVehiclesStepData, recoveryEquipments: IDropdownOption[]) => {
  const equipmentIdToName = {} as Record<string, string>;
  recoveryEquipments.forEach(({ value, label }) => {
    equipmentIdToName[value] = label;
  });
  const recoveryEquipment = data.recovery_equipment?.length
    ? data.recovery_equipment
        .map(equipmentId => equipmentIdToName[equipmentId])
        .filter(Boolean)
        .join(', ')
    : null;

  return [
    ...addOptionalLine('4-5 vehicles available?', yesNoLabel(data.av_available, '')),
    {
      label: 'Training area available?',
      value: yesNoLabel(data.safe_area),
    },
    ...addOptionalLine('Which recovery equipment is available in the vehicles?', recoveryEquipment),
  ];
};

export const getAttendeesData = (data: IAttendeesStepData) => {
  return [
    {
      label: 'Number of participants',
      value: data.num_of_attendees,
    },
  ];
};

export const getCommunicationsData = (
  data: ICommunicationsStepData,
  supervisorRoles: IDropdownOption[],
) => {
  const supervisor = [
    supervisorRoles.find(({ value }) => value === data.supervisor.role)?.label,
    `${data.supervisor.first_name} ${data.supervisor.last_name}`,
    data.supervisor.phone_number,
    data.supervisor.email,
  ]
    .filter(Boolean)
    .join(', ');
  let focalPoint = null;
  if (data.focal_point && Object.values(data.focal_point).some(Boolean)) {
    focalPoint = [
      `${data.focal_point.first_name} ${data.focal_point.last_name}`.trim(),
      data.focal_point.phone_number,
      data.focal_point.email,
    ]
      .filter(Boolean)
      .join(', ');
  }
  return [
    {
      label: 'Supervisor',
      value: supervisor,
    },
    ...addOptionalLine('Primary focal point', focalPoint),
  ];
};

export const getTrainerData = (data: ITrainerStepData) => {
  return [
    {
      label: 'Require a visa?',
      value: yesNoLabel(data.trainers_require_visa),
    },
    ...addOptionalLine(
      'Visa details',
      data.trainers_require_visa ? data.trainers_require_visa_comment : null,
    ),
    {
      label: 'Require any medical clearance?',
      value: yesNoLabel(data.trainers_require_medical_clearence),
    },
    ...addOptionalLine(
      'Medical clearance details',
      data.trainers_require_medical_clearence
        ? data.trainers_require_medical_clearence_comment
        : null,
    ),
    ...addOptionalLine("Travel or 'in-country' advice", data.travel_advice),
  ];
};
