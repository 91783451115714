import { FC, useEffect } from 'react';
import { InputText } from '@unbooking/ui-kit';
import { IEditComponent } from './types';
import {
  FieldValidationWarning,
  nonEmptyValidation,
} from '@/components/forms/RequestTrainingForm/common/FieldValidationWarning';

export const ParticipantsEdit: FC<IEditComponent> = ({
  trainingRequest,
  updateTrainingRequest,
  setConfirmEnabled,
}) => {
  const numOfAttendeesValidator = nonEmptyValidation(trainingRequest.num_of_attendees);
  const confirmEnabled = [numOfAttendeesValidator].every(validator => validator === null);

  useEffect(() => {
    setConfirmEnabled(confirmEnabled);
  }, [confirmEnabled]);

  return (
    <div className="flex flex-col w-full gap-10">
      <div className="flex flex-col items-stretch mb-3 gap-2">
        <div>Number of participants</div>
        <InputText
          light
          value={trainingRequest.num_of_attendees ?? ''}
          onChange={e => updateTrainingRequest('num_of_attendees', e.target.value)}
          name="num-of-attendees"
          placeholder="..."
        />
        <FieldValidationWarning light result={numOfAttendeesValidator} />
      </div>
    </div>
  );
};

export default ParticipantsEdit;
