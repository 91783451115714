import usePlannerFilters from './usePlannerFilters';
import { useMemo } from 'react';
import dayjs from 'dayjs';

const usePlannerDates = function () {
  const { filters } = usePlannerFilters();

  const firstOfMonth = useMemo(
    () => dayjs(`${filters.year}-${filters.month}-01`).startOf('day'),
    [filters.year, filters.month],
  );

  const lastOfMonth = useMemo(() => firstOfMonth.endOf('month').endOf('day'), [firstOfMonth]);

  const startOffset = useMemo(
    () => (firstOfMonth.day() ? firstOfMonth.day() - 1 : 6),
    [firstOfMonth],
  );

  const endOffset = useMemo(() => 7 - (lastOfMonth.day() || 7), [lastOfMonth]);

  const firstDay = useMemo(
    () => firstOfMonth.subtract(startOffset, 'day'),
    [firstOfMonth, startOffset],
  );

  const lastDay = useMemo(() => lastOfMonth.add(endOffset, 'day'), [lastOfMonth, endOffset]);

  return useMemo(
    () => ({
      firstOfMonth,
      lastOfMonth,
      startOffset,
      endOffset,
      firstDay,
      lastDay,
    }),
    [firstOfMonth, lastOfMonth, startOffset, endOffset, firstDay, lastDay],
  );
};

export default usePlannerDates;
