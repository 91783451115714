import { Button } from '@unbooking/ui-kit';
import { FC, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { ITrainingProgramContent } from '@/common/api/cms';
import { CalendarOptionIcon, DownloadIcon, DownArrow } from '@icons';
import { PATHS } from '@/common/path';
import { DownloadButton } from '@components';

interface ITrainingProgramDescription {
  trainingProgramContent: ITrainingProgramContent;
}

export const TrainingProgramDescription: FC<ITrainingProgramDescription> = ({
  trainingProgramContent,
}) => {
  const trainingUrl = `${PATHS.requestTraining}?program=${trainingProgramContent.training_program.id}`;
  const moreInfoRef = useRef<HTMLDivElement | null>(null);

  const scrollToMoreInfo = () => {
    if (moreInfoRef.current) {
      moreInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // TO DO not hardcode paragraphs?
  const commonInfoSection = trainingProgramContent.paragraphs?.slice(0, 2);
  const languageInfoBlock = trainingProgramContent.paragraphs?.[2];
  const moreInfoSection = trainingProgramContent.paragraphs?.slice(3);

  return (
    <div className="w-full">
      <div className="text-black px-10 py-5">
        <div className="p-6 bg-[#ffffff] min-h-[calc(100vh-239px)] w-1/2 min-w-[min(700px,100%)] flex flex-col">
          <div className="cms-block">
            <div className="flex gap-3 items-center mb-4">
              {trainingProgramContent.training_program?.icon ? (
                <img
                  className="flex-shrink-0 w-[40px] h-auto"
                  src={trainingProgramContent.training_program.icon}
                  alt="Program icon"
                />
              ) : null}
              <h1
                className="text-4xl font-bold"
                dangerouslySetInnerHTML={{ __html: trainingProgramContent.training_program.title }}
              />
            </div>
            <p
              className="font-light mb-2"
              dangerouslySetInnerHTML={{ __html: trainingProgramContent.description }}
            />
          </div>
          <div>
            {commonInfoSection?.map(block => (
              <div className="mb-5 cms-block" key={block.id}>
                {block.title && (
                  <p
                    className="cms-content font-extrabold mb-3"
                    dangerouslySetInnerHTML={{ __html: block.title }}
                  />
                )}
                <p className="font-light" dangerouslySetInnerHTML={{ __html: block.description }} />
              </div>
            ))}
            <div className="flex justify-between">
              <div className="mb-5 cms-block">
                {languageInfoBlock?.title && (
                  <p
                    className="cms-content font-extrabold mb-3"
                    dangerouslySetInnerHTML={{ __html: languageInfoBlock.title }}
                  />
                )}
                {languageInfoBlock?.description && (
                  <p
                    className="font-light"
                    dangerouslySetInnerHTML={{ __html: languageInfoBlock.description }}
                  />
                )}
              </div>
              <div className="flex justify-end mt-2 whitespace-nowrap">
                <NavLink to={trainingUrl}>
                  <Button label="Request this training" endIcon={<CalendarOptionIcon />} />
                </NavLink>
              </div>
            </div>
          </div>
          <div
            onClick={scrollToMoreInfo}
            className="flex items-center m-auto mb-0  underline cursor-pointer"
          >
            <span className="pr-2 pt-1">
              <DownArrow />
            </span>
            Discover more
          </div>
        </div>
      </div>
      <div
        ref={moreInfoRef}
        className="bg-[#ffffff] text-black flex flex-1 flex-col justify-start gap-5 px-10 pb-10 pt-2"
      >
        <div className="flex justify-end items-center font-bold text-blue cursor-pointer">
          <DownloadButton
            plain
            url={`/trainings/training-programs/${trainingProgramContent.training_program.id}/download-spec-sheet/`}
            startIcon={<DownloadIcon />}
            label="Download training spec sheet"
          />
        </div>
        <div className="lg:columns-2 gap-10 cms-block">
          {moreInfoSection.map(paragraph => (
            <div className="mb-5 cms-block" key={paragraph.id}>
              {paragraph.title && (
                <p
                  className="cms-content font-bold"
                  dangerouslySetInnerHTML={{ __html: paragraph.title }}
                />
              )}
              <p
                className="font-light"
                dangerouslySetInnerHTML={{ __html: paragraph.description }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
