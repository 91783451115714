import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MainInfo, RequestDetails } from './components';
import { Spinner } from '@components';
import { getDOStatuses, getDOTrainingRequestDetails } from '@/common/api/digitalOffice';

interface ITrainingRequestDetail {
  requestId: string | number;
}

export const TrainingRequestDetail: FC<ITrainingRequestDetail> = ({ requestId }) => {
  const { isFetching, data } = useQuery({
    queryKey: ['do-training-request-detail', requestId],
    queryFn: () => getDOTrainingRequestDetails(requestId),
  });

  const { isLoading: statusLoading, data: statuses = [] } = useQuery({
    queryKey: ['do-statuses'],
    queryFn: getDOStatuses,
  });

  const isLoading = statusLoading || isFetching;

  return (
    <div className="w-full">
      {isLoading && <Spinner fullScreen />}
      {data ? (
        <>
          <MainInfo trainingRequest={data} statuses={statuses} />
          {/*Separator*/}
          <div className="pb-5" />
          <RequestDetails trainingRequest={data} statuses={statuses} />
          <div className="pb-5" />
        </>
      ) : null}
    </div>
  );
};

export default TrainingRequestDetail;
