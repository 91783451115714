import { useMemo } from 'react';
import { group } from '../../../../utils/generic';
import Slots from '../../../../utils/slots';
import usePlannerEvents from '../../../../hooks/planner/usePlannerEvents';
import CalendarDay from '../../../../interfaces/planner/CalendarDay';
import usePlannerDates from '../../../../hooks/planner/usePlannerDates';
import PlannerCalendarWeek from './PlannerCalendarWeek';

const PlannerCalendarBody = () => {
  const { firstDay, lastDay } = usePlannerDates();
  const monthEvents = usePlannerEvents();

  const dates = useMemo(() => {
    const slots = Slots();
    const dates = [] as CalendarDay[];
    let date = firstDay;

    while (date.isSame(lastDay, 'day') || date.isBefore(lastDay, 'day')) {
      const showMonth = dates.length === 0 || dates[dates.length - 1].date.date() > date.date();

      const events = monthEvents
        ?.filter(booking => {
          const started =
            date.isSame(booking.fromDate, 'day') || date.isAfter(booking.fromDate, 'day');
          const ended = date.isAfter(booking.toDate, 'day');
          return started && !ended;
        })
        .map(booking => {
          const starting = date.isSame(booking.fromDate, 'day');
          const ending = date.isSame(booking.toDate, 'day');
          const slot = slots.getSlot(booking.id);
          ending && slots.addRemoving(slot);
          return { ...booking, starting, ending, slot };
        })
        .sort((a, b) => a.slot - b.slot);

      slots.remove();
      dates.push({ date, showMonth, events });
      date = date.add(1, 'day');
    }

    return dates;
  }, [monthEvents, firstDay, lastDay]);

  return (
    <div data-testid="week-row" className="flex flex-col w-full">
      {group(dates, 7).map((weekDays, index) => (
        <PlannerCalendarWeek key={index} weekDays={weekDays} />
      ))}
    </div>
  );
};

export default PlannerCalendarBody;
