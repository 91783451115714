import { apiGet } from '../axios';
import { ListResponse, PageParams } from '../interfaces';

export interface HistoryItem {
  user: string;
  date: string;
  field: string;
  old: string;
  new: string;
}

const getHistory = (requestId: string, params: PageParams) =>
  apiGet<ListResponse<HistoryItem>>(
    `/digital-office/training-requests/${requestId}/history/`,
    params,
  );

export { getHistory };
