import { AxiosError } from 'axios';
import { toast, ToastOptions } from 'react-toastify';

interface Errors {
  [key: string]: Errors | string | Errors[];
}

const defaultMessage = `Something went wrong! Please, try again.
If the error persists, contact the Support Team.`;

const isStringHTML = (str: string): boolean => {
  const htmlRegex = /<[a-z][\s\S]*>/i;
  return htmlRegex.test(str);
};

const parseObject = (error: Errors, includeKey = false): string => {
  const text: string[] = [];

  for (const key in error) {
    const prefix = includeKey ? `${key}: ` : '';
    const value = error[key];

    if (typeof value === 'string') {
      text.push(`${prefix}${value}`);
    } else if (Array.isArray(value)) {
      const newArrayWithoutEmptyObjects = value.filter(obj => Object.keys(obj).length !== 0);
      // If value is Array - take first item, parse it again if it's an object
      const suffix =
        typeof newArrayWithoutEmptyObjects[0] === 'object'
          ? parseObject(newArrayWithoutEmptyObjects[0] as Errors, includeKey)
          : newArrayWithoutEmptyObjects[0];
      text.push(`${prefix}${suffix}`);
    } else if (typeof value === 'object') {
      // If value is Object - parse it again
      text.push(`${prefix}${parseObject(value as Errors, includeKey)}`);
    }
  }

  return text.join('; ') || defaultMessage;
};

export const handleError = (error: AxiosError, includeKey = false, message = defaultMessage) => {
  // Show the error even if it is not in the request
  console.error(error);

  const toastOptions: ToastOptions = {
    style: {
      whiteSpace: 'pre-line',
      textAlign: 'left',
      width: '400px',
    },
  };
  if (!error.response) {
    toast.error(defaultMessage, toastOptions);
    return;
  }

  const { data } = error.response;

  if (typeof data === 'string' && !isStringHTML(data)) {
    message = data;
  }

  if (typeof data === 'object' && !(data instanceof Blob)) {
    message = parseObject(data as Errors, includeKey);
  }

  toast.error(message, toastOptions);
};
