export const generateLargeColorPalette = (hexColor: string, numOfShades: number) => {
  const [hue, saturation, lightness] = hexToHsl(hexColor);
  const lightnessStep = (70 - lightness) / numOfShades;

  return Array.from({ length: numOfShades }, (_, index: number) => {
    const adjustedLightness = Math.min(lightness + index * lightnessStep, 100);
    return hslToHex(hue, saturation, adjustedLightness);
  });
};

const hexToHsl = (hex: string): [number, number, number] => {
  const red = parseInt(hex.slice(1, 3), 16) / 255;
  const green = parseInt(hex.slice(3, 5), 16) / 255;
  const blue = parseInt(hex.slice(5, 7), 16) / 255;

  const maxColorValue = Math.max(red, green, blue);
  const minColorValue = Math.min(red, green, blue);
  let hue = 0;
  let saturation = 0;
  const lightness = (maxColorValue + minColorValue) / 2;

  if (maxColorValue !== minColorValue) {
    const colorDifference = maxColorValue - minColorValue;
    saturation =
      lightness > 0.5
        ? colorDifference / (2 - maxColorValue - minColorValue)
        : colorDifference / (maxColorValue + minColorValue);

    switch (maxColorValue) {
      case red:
        hue = (green - blue) / colorDifference + (green < blue ? 6 : 0);
        break;
      case green:
        hue = (blue - red) / colorDifference + 2;
        break;
      case blue:
        hue = (red - green) / colorDifference + 4;
        break;
    }
    hue *= 60;
  }

  return [hue, saturation * 100, lightness * 100];
};

const hslToHex = (hue: number, saturation: number, lightness: number) => {
  const chroma = (1 - Math.abs((2 * lightness) / 100 - 1)) * (saturation / 100);
  const x = chroma * (1 - Math.abs(((hue / 60) % 2) - 1));
  const m = lightness / 100 - chroma / 2;

  let red = 0,
    green = 0,
    blue = 0;

  if (hue < 60) [red, green, blue] = [chroma, x, 0];
  else if (hue < 120) [red, green, blue] = [x, chroma, 0];
  else if (hue < 180) [red, green, blue] = [0, chroma, x];
  else if (hue < 240) [red, green, blue] = [0, x, chroma];
  else if (hue < 300) [red, green, blue] = [x, 0, chroma];
  else [red, green, blue] = [chroma, 0, x];

  return `#${[red, green, blue]
    .map(channel =>
      Math.round((channel + m) * 255)
        .toString(16)
        .padStart(2, '0'),
    )
    .join('')}`;
};
