import { FC, useEffect, useState } from 'react';
import YesNoInput from '../../../inputs/YesNoInput';
import { InputDropdown, InputDropdownItem } from '@unbooking/ui-kit';
import { IEditComponent } from './types';
import {
  FieldValidationWarning,
  nonEmptyValidation,
} from '@/components/forms/RequestTrainingForm/common/FieldValidationWarning';
import InputWarning from '@/components/forms/RequestTrainingForm/FormStepComponents/components/InputWarning';

export const VehiclesEdit: FC<IEditComponent> = ({
  originalTrainingRequest,
  trainingRequest,
  updateTrainingRequest,
  setConfirmEnabled,
  context,
}) => {
  const [selectedEquipment, setSelectedEquipment] = useState<string[]>(
    () =>
      originalTrainingRequest.recovery_equipment?.map(equipment => equipment?.id ?? equipment) ??
      [],
  );

  const updateEquipmentDropdown = (values: string[]) => {
    setSelectedEquipment(values);
    updateTrainingRequest('recovery_equipment', values);
  };

  const fieldValidators: Record<string, string | null> = {
    av_available: nonEmptyValidation(trainingRequest.av_available),
    safe_area: nonEmptyValidation(trainingRequest.safe_area),
  };

  const confirmEnabled = Object.values(fieldValidators).every(validator => validator === null);

  const recoveryEquipmentOptions = (context?.recovery_equipment || []) as InputDropdownItem[];

  useEffect(() => {
    setConfirmEnabled(confirmEnabled);
  }, [confirmEnabled]);

  return (
    <div className="flex flex-col w-full gap-10">
      <div className="flex flex-col items-stretch mb-2 gap-2">
        <div>
          Please note that 4-5 vehicles are required for the duration of the training (1 vehicle per
          2 participants). Are these available?
        </div>
        <div>
          <YesNoInput
            light
            value={trainingRequest.av_available}
            onChange={value => updateTrainingRequest('av_available', value)}
          />
          <FieldValidationWarning light result={fieldValidators.av_available} />
        </div>
        {trainingRequest.av_available === false && (
          <InputWarning
            text={
              'Please note that the above-mentioned resources & facilities are required for the training to take place. Depending on the type of training, it might be impossible to accommodate your request if requirements are not fully met.'
            }
          />
        )}
      </div>
      <div className="flex flex-col items-stretch mb-2 gap-2">
        <div>
          Is there a safe, secure and spacious (open) training ground (minimum 300m x 50m) available
          where the dynamic driving techniques can be demonstrated and practiced?
        </div>
        <div>
          <YesNoInput
            light
            value={trainingRequest.safe_area}
            onChange={value => updateTrainingRequest('safe_area', value)}
          />
          <FieldValidationWarning light result={fieldValidators.safe_area} />
        </div>
        {trainingRequest.safe_area === false && (
          <InputWarning
            text={
              'Please note that the above-mentioned resources & facilities are required for the training to take place. Depending on the type of training, it might be impossible to accommodate your request if requirements are not fully met.'
            }
          />
        )}
      </div>
      <div className="flex flex-col items-stretch mb-3">
        <div className="mb-2">Which recovery equipment is available in the vehicles?</div>
        <InputDropdown
          light
          value={selectedEquipment}
          onChange={e => updateEquipmentDropdown(e.target.value as unknown as string[])}
          items={recoveryEquipmentOptions}
          name="recovery-equipment-select"
          placeholder="Select equipment"
          multiple
        />
      </div>
    </div>
  );
};

export default VehiclesEdit;
