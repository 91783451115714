import { Button } from '@unbooking/ui-kit';
import { ArrowIcon } from '@icons';
import { FC } from 'react';
import { Tip } from '@components';

interface IPreviousStepButton {
  goToPreviousStep: () => void;
}

const PreviousStepButton: FC<IPreviousStepButton> = ({ goToPreviousStep }) => {
  return (
    <div className="self-start mt-10">
      <Button
        label="Previous"
        onClick={goToPreviousStep}
        startIcon={<ArrowIcon direction="left" />}
      />
    </div>
  );
};

interface INextStepButton {
  goToNextStep: () => void;
  disabled: boolean;
}

const NextStepButton: FC<INextStepButton> = ({ goToNextStep, disabled }) => {
  return (
    <div className="self-end mt-10">
      <Tip text="Please fill in the necessary information to proceed" isVisible={disabled}>
        <Button label="Next" onClick={goToNextStep} endIcon={<ArrowIcon />} disabled={disabled} />
      </Tip>
    </div>
  );
};

export { PreviousStepButton, NextStepButton };
