import { apiGet, apiPatch, apiPost, apiPut } from '../axios';
import { TrainingType } from '../enums';
import { ListResponse } from '../interfaces';
import { ISimpleAgency } from './agencies';
import { User } from './users';
import {
  IContactPerson,
  ISupervisor,
} from '@/components/forms/RequestTrainingForm/common/interfaces';
import { IRecoveryEquipment } from './trainings';
import { RemarkMessage } from '@/components/digital-office/training-requests/details/components/Remarks';

export interface DOPermissionData {
  is_do: boolean;
}

export interface DODashboardData {
  new_training_requests_ids: string[];
}

export interface IStatus {
  status: string;
  value: string;
  label: string;
  user_label: string;
  color: string;
  background_color: string;
}

export interface DOTrainingRequestFilters {
  training_type?: TrainingType;
  status?: string;
  start_date?: string;
  end_date?: string;
}

export interface DOTrainingRequestParams extends DOTrainingRequestFilters {
  page: number;
  page_size?: number;
  search?: string;
}

export interface SimpleTrainingRequest {
  id: string;
  booked_by: User;
  request_id: string;
  agencies: ISimpleAgency[];
  other_agencies?: string;
  program: string;
  start_date: string;
  end_date: string;
  num_of_attendees: string;
  status: string;
  status_display: string;
  status_option?: IStatus;
}

export interface TrainingRequestDetails extends SimpleTrainingRequest {
  reason: string;
  remarks: string;
  weekend_training_available?: boolean;
  weekend_training_days: string | null;
  country_location: string;
  country_location_name: string;
  preferred_place: string;
  co_working_hours: string;
  av_available: boolean | null;
  safe_area: boolean | null;
  recovery_equipment?: IRecoveryEquipment[];
  focal_point: IContactPerson | null;
  supervisor: ISupervisor;
  trainers_require_visa: boolean | null;
  trainers_require_visa_comment?: string;
  trainers_require_medical_clearence: boolean | null;
  trainers_require_medical_clearence_comment?: string;
  travel_advice: string;
}

export const getDOPermissionData = async () => {
  const { data } = await apiGet<DOPermissionData>('/digital-office/permissions/');
  return data;
};

export const getDODashboardData = async () => {
  const { data } = await apiGet<DODashboardData>('/digital-office/dashboard/');
  return data;
};

export const getDOTrainingRequests = async (params: DOTrainingRequestParams) => {
  const { data } = await apiGet<ListResponse<SimpleTrainingRequest>>(
    '/digital-office/training-requests/',
    params,
  );
  return data;
};

export const getDOAnalytics = async (params: any) => {
  const { data } = await apiGet<ListResponse<SimpleTrainingRequest>>(
    '/digital-office/training-requests/analytics/',
    params,
  );
  return data;
};

// TO DO will be added pagination instead hardcode limit
export const getDORequestOverview = async (trainingId: number | string) => {
  const { data } = await apiGet<ListResponse<RemarkMessage>>(
    `/trainings/${trainingId}/remarks/?limit=1000`,
  );
  return data;
};

export const addDORequestOverviewRemark = ({
  requestId,
  body,
}: {
  requestId: string;
  body: string;
}) => {
  return apiPost(`/trainings/${requestId}/add-remarks/`, [
    {
      body,
      type: 'DO_REMARK',
    },
  ]);
};

export const getDOTrainingRequestDetails = async (requestId: number | string) => {
  const { data } = await apiGet<TrainingRequestDetails>(
    `/digital-office/training-requests/${requestId}/`,
  );
  return data;
};

export const getDOStatuses = async (): Promise<IStatus[]> => {
  const { data } = await apiGet<IStatus[]>(`/digital-office/status-configuration/`);
  return data.map(status => ({ ...status, value: status.status }));
};

export const updateDOTrainingRequestStatus = ({
  requestId,
  status,
}: {
  requestId: string;
  status: string;
}) => {
  return apiPut(`/digital-office/training-requests/${requestId}/`, { status });
};

export const updateDOTrainingRequest = ({
  requestId,
  data,
}: {
  requestId: string;
  data: Partial<TrainingRequestDetails>;
}) => {
  return apiPatch(`/digital-office/training-requests/${requestId}/`, data);
};
