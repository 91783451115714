import { EditIcon } from '@/assets/icons';
import { Button } from '@unbooking/ui-kit';
import React from 'react';
import { IEditSection } from '../../../edit/types';

interface RequestDetailsTabProps {
  sections: IEditSection[];
  setEditSection: (section: IEditSection) => void;
  setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RequestDetailsTab: React.FC<RequestDetailsTabProps> = ({
  sections,
  setEditSection,
  setEditModalOpen,
}) => {
  return (
    <section className="bg-catskill-white p-2">
      <div className="bg-white-light">
        <div className="flex border-gray-300 flex-wrap">
          {sections.map(section => (
            <div
              className="basis-1/3 flex-grow min-w-[min(500px,100%)] p-5 border-r-2 border-b-2 border-dotted border-gray-300"
              key={section.title}
            >
              <div className="flex gap-2 items-center fill-highlight text-xl">
                {section.icon} <b>{section.title}</b>
                {section.sectionType && (
                  <>
                    <div className="flex-1" />
                    <Button
                      label="Edit"
                      startIcon={<EditIcon />}
                      plain
                      color="primary"
                      onClick={() => {
                        setEditSection(section);
                        setEditModalOpen(true);
                      }}
                    />
                  </>
                )}
              </div>
              <div className="mt-8 flex flex-col gap-4">
                {section.data.map(({ label, value, type }, index) => (
                  <div className="flex items-baseline gap-4" key={index}>
                    {type === 'subtitle' ? (
                      <label className="text-oslo-gray font-bold flex-1">{label}</label>
                    ) : (
                      <>
                        <label className="text-oslo-gray basis-[240px] flex-[60%]">{label}</label>
                        <div className="flex-[40%]">{value}</div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default RequestDetailsTab;
