export const CalendarAddIcon = () => (
  <svg
    className="inline-block"
    width="1em"
    height="1em"
    viewBox="0 0 17 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 12.125H12.375V10.25H11.125V12.125H9.25V13.375H11.125V15.25H12.375V13.375H14.25V12.125Z"
      fill="currentColor"
    />
    <path
      d="M11.75 17.75C9 17.75 6.75 15.5 6.75 12.75C6.75 10 9 7.75 11.75 7.75C14.5 7.75 16.75 10 16.75 12.75C16.75 15.5 14.5 17.75 11.75 17.75ZM11.75 9C9.6875 9 8 10.6875 8 12.75C8 14.8125 9.6875 16.5 11.75 16.5C13.8125 16.5 15.5 14.8125 15.5 12.75C15.5 10.6875 13.8125 9 11.75 9Z"
      fill="currentColor"
    />
    <path
      d="M15.5 2.75C15.5 2.0625 14.9375 1.5 14.25 1.5H11.75V0.25H10.5V1.5H5.5V0.25H4.25V1.5H1.75C1.0625 1.5 0.5 2.0625 0.5 2.75V15.25C0.5 15.9375 1.0625 16.5 1.75 16.5H5.5V15.25H1.75V2.75H4.25V4H5.5V2.75H10.5V4H11.75V2.75H14.25V6.5H15.5V2.75Z"
      fill="currentColor"
    />
  </svg>
);

export default CalendarAddIcon;
