import React, { FC, useMemo } from 'react';
import { InputDropdown, InputDropdownItem } from '@unbooking/ui-kit';
import { ICountryTrainings } from '@/common/api/impact';

export interface ICountrySelectStats {
  numOfTrainings: number;
  numOfDrivers: number;
  numOfCountries: number | undefined;
}

interface ICountrySelect {
  selectedCountry: string | null;
  onCountrySelect: (value: string) => void;
  countryList: ICountryTrainings[];
  stats: ICountrySelectStats;
}

const CountrySelect: FC<ICountrySelect> = ({
  selectedCountry,
  onCountrySelect,
  countryList,
  stats,
}) => {
  const items = useMemo<InputDropdownItem[]>(() => {
    return countryList?.map(country => ({
      label: country.name,
      value: country.code,
    }));
  }, [countryList]);

  return (
    <div className="w-96 bg-black/75 fixed bottom-[65px] transition-all duration-1000 z-10 px-6">
      <div className="text-xl text-white pt-5 w-4/5">Discover the Road Safety Academy</div>
      <div className="w-full flex justify-start items-center py-5 flex-wrap gap-x-6 text-orange-400">
        <div>
          <p className="text-2xl">{stats.numOfTrainings}</p>
          <p className="text-sm">Trainings held</p>
        </div>
        <div>
          <p className="text-2xl">{stats.numOfDrivers}</p>
          <p className="text-sm">Staff trained</p>
        </div>
        {stats.numOfCountries ? (
          <div>
            <p className="text-2xl">{stats.numOfCountries}</p>
            <p className="text-sm">Countries</p>
          </div>
        ) : null}
      </div>
      <div className="flex flex-col pb-4">
        <InputDropdown
          name="ImpactCountrySelect"
          placeholder="Select a country"
          onChange={e => onCountrySelect(e.target.value)}
          items={items}
          value={selectedCountry as string}
          allowEmpty
          emptyLabel="All countries"
          isSearchable
        />
      </div>
    </div>
  );
};

export default CountrySelect;
