import React, { FC, useMemo, useState } from 'react';
import { Table } from '@unbooking/ui-kit';
import { ArrowIcon, CalendarIcon } from '@icons';
import { HistoryItem, getHistory } from '@/common/api/history';
import { useQuery } from '@tanstack/react-query';
import { IStatus, TrainingRequestDetails } from '@/common/api/digitalOffice';
import { Spinner } from '@components';
import dayjs from 'dayjs';

interface IHistory {
  trainingRequest: TrainingRequestDetails;
  statuses: IStatus[];
}

interface IUpdateOccurrenceCell {
  data: HistoryItem;
  statuses: IStatus[];
}

const Status: FC<{
  status?: IStatus;
}> = ({ status }) => {
  if (!status) return null;

  const { label, background_color: backgroundColor, color } = status;

  return (
    <div className="rounded-lg font-bold py-1 px-2" style={{ color, backgroundColor }}>
      <span className="scale-50 mr-2 inline-block">⬤</span>
      {label || '-'}
    </div>
  );
};

const UpdateOccurrenceCell: FC<IUpdateOccurrenceCell> = ({ data, statuses }) => {
  const { field, old, new: newState } = data;

  if (field === 'status') {
    const oldStatus = statuses.find(({ value }) => value === old);
    const newStatus = statuses.find(({ value }) => value === newState);

    return (
      <div className="flex items-center gap-4">
        <span className="italic">Status: </span>
        <div className="flex items-center gap-2">
          <Status status={oldStatus} />
          <ArrowIcon />
          <Status status={newStatus} />
        </div>
      </div>
    );
  }

  const label = field.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());

  return (
    <div className="flex items-center gap-4">
      <span className="italic">{`${label}: `}</span>
      <div className="flex items-center gap-2">
        {old}
        <ArrowIcon />
        <span className="font-bold">{newState}</span>
      </div>
    </div>
  );
};

const History: FC<IHistory> = ({ trainingRequest, statuses }) => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [rowLimit, setRowLimit] = useState(10);

  const onOffsetChange = (offset: number) => {
    const page = Math.floor(offset / rowLimit) + 1;
    setPage(page);
  };

  const { data, isFetching } = useQuery({
    queryKey: ['history', page, rowLimit],
    queryFn: async () => {
      const { data } = await getHistory(trainingRequest.request_id, {
        page,
        page_size: rowLimit,
      });
      setCount(data.count);
      return data;
    },
  });

  const columns = useMemo(
    () => [
      {
        label: 'Updated by',
        field: 'user',
        render: ({ user }: HistoryItem) => user || 'System',
      },
      {
        label: 'Update date',
        field: 'date',
        render: ({ date }: HistoryItem) => dayjs(date).format('DD MMM YYYY'),
      },
      {
        label: 'Update occurrence',
        field: 'field',
        render: (item: HistoryItem) => <UpdateOccurrenceCell data={item} statuses={statuses} />,
      },
    ],
    [],
  );

  return (
    <section>
      {isFetching && <Spinner fullScreen />}
      <div>
        <div className="pb-4 flex gap-3 items-center">
          <CalendarIcon />
          History of updates
        </div>
        <div className="border-b border-gray-lighter">
          <Table
            columns={columns}
            items={data?.results}
            count={count}
            limit={rowLimit}
            offset={rowLimit * (page - 1)}
            onChangeLimit={setRowLimit}
            onChangeOffset={onOffsetChange}
            light
          />
        </div>
      </div>
    </section>
  );
};

export default History;
