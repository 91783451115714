import React, { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PATHS } from '@/common/path';
import { AttendeesIcon, CalendarOptionIcon, LocationIcon, SendIcon, ArrowIcon } from '@icons';
import { pluralize } from '@/common/utils';
import {
  IStatus,
  TrainingRequestDetails,
  updateDOTrainingRequestStatus,
} from '@/common/api/digitalOffice';
import { getAgencies, getEventDate } from '../../../utils';
import { StatusDropdown } from '../../../common';
import { handleError } from '@/common/handleError';
import { Modal, Spinner } from '@components';

interface IMainInfo {
  trainingRequest: TrainingRequestDetails;
  statuses: IStatus[];
}

const MainInfo: FC<IMainInfo> = ({ trainingRequest, statuses }) => {
  const agencies = useMemo(() => getAgencies(trainingRequest), [trainingRequest]);
  const eventDate = useMemo(() => getEventDate(trainingRequest), [trainingRequest]);

  const [currentStatus, setCurrentStatus] = useState(trainingRequest.status);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const updateStatusMutation = useMutation({
    mutationFn: updateDOTrainingRequestStatus,
  });

  const updateStatus = async (newStatus: string, confirmed = false) => {
    if (!confirmed) {
      if (newStatus === currentStatus) return;
      setCurrentStatus(newStatus);
      setStatusModalOpen(true);
      return;
    }
    try {
      await updateStatusMutation.mutateAsync({
        requestId: trainingRequest.request_id,
        status: newStatus,
      });
      toast.success('Status successfully changed');
      setStatusModalOpen(false);
      // Invalidate and refetch
      await queryClient.invalidateQueries({ queryKey: ['do-training-request-detail'] });
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  const onStatusCancel = () => {
    setCurrentStatus(trainingRequest.status);
    setStatusModalOpen(false);
  };

  const isLoading = updateStatusMutation.isPending;
  const agencyLabel = pluralize(trainingRequest?.agencies.length, 'agency', 'agencies');

  return (
    <section className="bg-catskill-white m-5 p-5 pb-12">
      {isLoading && <Spinner fullScreen />}
      <Link
        className="flex items-center gap-2 font-medium mb-3 w-fit p-4 -ml-4 -mt-4 text-sky-500 hover:text-sky-600"
        to={PATHS.digitalOfficeTrainings}
      >
        <ArrowIcon direction="left" className="inline-block" /> All requests list
      </Link>
      <div className="text-sm">Request ID: {trainingRequest.request_id}</div>
      <h1 className="text-3xl mb-6">{trainingRequest.program}</h1>
      <div className="flex items-baseline gap-4">
        <span className="text-oslo-gray">Requestor:</span>{' '}
        <strong>
          {trainingRequest.booked_by.first_name} {trainingRequest.booked_by.last_name}
        </strong>
        <a
          className="flex items-center gap-1 text-sky-500 hover:text-sky-600"
          href={`mailto:${trainingRequest.booked_by.email}`}
        >
          <SendIcon />
          <span className="ml-2">Send email</span>
        </a>
      </div>
      <div className="mb-8">
        <span className="text-oslo-gray">{`Training requested for ${agencyLabel}:`}</span>{' '}
        {agencies}
      </div>
      <div className="flex flex-wrap whitespace-nowrap items-center gap-8">
        <div className="flex gap-2 items-center">
          <LocationIcon stroke="black" /> {trainingRequest.country_location_name}
        </div>
        <div className="flex gap-2 items-center">
          <CalendarOptionIcon />
          {eventDate}
        </div>
        <div className="flex gap-2 items-center">
          <AttendeesIcon />
          {trainingRequest.num_of_attendees} participant
          {pluralize(Number(trainingRequest.num_of_attendees))}
        </div>
        <div className="flex-1" />
        <div className="flex basis-[450px] gap-6 items-center pr-10">
          <label className="font-bold">Request status</label>

          <StatusDropdown status={currentStatus} options={statuses} onChange={updateStatus} />
        </div>
      </div>
      <Modal
        isDismissable
        isOpen={statusModalOpen}
        onClose={onStatusCancel}
        title="Confirm status change"
        size="small"
      >
        <Modal.Body>
          <div className="text-center">Do you really want to confirm the status change?</div>
        </Modal.Body>
        <Modal.Footer
          buttonPosition="center"
          onCancel={onStatusCancel}
          onConfirm={() => updateStatus(currentStatus, true)}
        />
      </Modal>
    </section>
  );
};

export default MainInfo;
