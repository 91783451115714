import { FC, useEffect, useState, type FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@unbooking/ui-kit';
import { useAuth } from '@unbooking/ui-auth';
import RoadSafetyFooter from '../../components/other/RoadSafetyFooter';
import { RSALogo, SignInBg } from '@/assets/img';
import { toast, ToastContainer } from 'react-toastify';

import './styles.css';

export const SignInPage: FC = () => {
  const { authorize, isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const initializeSignIn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = (e.target as HTMLFormElement).querySelector('input').value;
    if (!email) {
      toast.warning('Please enter you email address');
      return;
    }

    setIsLoading(true);

    try {
      await authorize(email);
    } catch (err: Error) {
      toast.error(err.message);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (isAuthenticated) navigate('/');
  }, [isAuthenticated, navigate]);

  return (
    <div data-testid="layout" className="flex flex-col h-screen w-full overflow-hidden">
      <div
        style={{ backgroundImage: `url(${SignInBg})` }}
        className="flex flex-1 bg-no-repeat bg-cover"
      >
        <form
          className="flex flex-col bg-training-details w-[500px] h-fit ml-40 mt-40 p-8"
          onSubmit={initializeSignIn}
        >
          <div className="-mt-8 mb-2 -mx-8">
            <img src={RSALogo} className="h-32" alt="Road Safety Academy Logo" />
          </div>
          <div className="text-2xl text-white font-light mb-5">
            Log in to <b>Road Safety Academy</b>
          </div>
          <div className="text-white mb-1">Work email</div>
          <div className="inline-flex items-center gap-4 input-dark">
            <input
              type="email"
              id="sign-in-email"
              name="sign-in-email"
              placeholder="name@example.com"
              autoComplete="email"
              className="signInEmail w-full appearance-none !bg-transparent outline-none"
            />
          </div>
          <div className="mt-5 flex flex-col">
            <Button type="submit" label="Log in" loading={isLoading} />
          </div>
        </form>
      </div>
      <RoadSafetyFooter />
      <ToastContainer
        theme="dark"
        position="top-center"
        closeButton={false}
        newestOnTop={true}
        closeOnClick
        limit={2}
        autoClose={2000}
      />
    </div>
  );
};

export default SignInPage;
