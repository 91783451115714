import { FC, useContext, useEffect } from 'react';
import { IRequestTrainingFormStepProps, RequestTrainingStep } from '../common/interfaces';
import BaseStep from './components/BaseStep';
import { RequestTrainingFormContext } from '../common/formContext';
import { nonEmptyValidation } from '../common/FieldValidationWarning';
import { getFullImageUrl } from '@/common/styles';
import cn from 'classnames';

const STEP = RequestTrainingStep.PROGRAM;

export const ProgramStep: FC<IRequestTrainingFormStepProps> = ({
  formData,
  updateFormData,
  goToNextStep,
  goToPreviousStep,
  updateStepCompleted,
}) => {
  const { formContext } = useContext(RequestTrainingFormContext);
  const formStep = formData[STEP];

  const programValidation = nonEmptyValidation(formStep.program);
  const nextStepEnabled = programValidation === null;

  useEffect(() => {
    updateStepCompleted(STEP, nextStepEnabled);
  }, [nextStepEnabled]);

  return (
    <BaseStep
      goToNextStep={goToNextStep}
      formStep={STEP}
      goToPreviousStep={goToPreviousStep}
      nextStepEnabled={nextStepEnabled}
    >
      <div className="flex flex-col w-full gap-10">
        <div className="flex flex-wrap items-stretch gap-4">
          {formContext?.programs?.map(trainingProgram => (
            <div
              key={trainingProgram.id}
              className={cn(
                'basis-full text-white mx-4 cursor-pointer lg:basis-[calc(50%-0.5rem)] xl:basis-[calc(33%-0.5rem)] lg:mx-0 hover:opacity-80',
                {
                  'outline outline-4 outline-sky-600 hover:opacity-100':
                    trainingProgram.id === formStep.program?.id,
                },
              )}
              onClick={() => updateFormData('program', trainingProgram)}
            >
              <img
                src={getFullImageUrl(trainingProgram.background_image_url || '')}
                alt="Training program"
                className="h-48 w-full object-cover"
                draggable="false"
              />
              <h1 className="my-3 text-lg font-bold px-2">{trainingProgram.title}</h1>
              {trainingProgram.short_description ? (
                <div
                  className="px-2 cms-block"
                  dangerouslySetInnerHTML={{ __html: trainingProgram.short_description }}
                />
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </BaseStep>
  );
};

export default ProgramStep;
