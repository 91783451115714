import { InputDropdown, InputDropdownItem, InputText } from '@unbooking/ui-kit';
import { FC, useEffect, useState } from 'react';
import { IEditComponent } from './types';
import {
  FieldValidationWarning,
  nonEmptyValidation,
} from '@/components/forms/RequestTrainingForm/common/FieldValidationWarning';

export const AgencyEdit: FC<IEditComponent> = ({
  originalTrainingRequest,
  trainingRequest,
  updateTrainingRequest,
  setConfirmEnabled,
  context,
}) => {
  const agencyOptions = context?.agencies ?? [];
  const [selectedAgencies, setSelectedAgencies] = useState<string[]>(
    () => originalTrainingRequest.agencies?.map(agency => agency?.id ?? agency) ?? [],
  );

  const updateAgenciesDropdown = (values: string[]) => {
    setSelectedAgencies(values);
    updateTrainingRequest('agencies', values);
  };

  const allAgencies = trainingRequest.other_agencies
    ? selectedAgencies.concat(trainingRequest.other_agencies)
    : selectedAgencies;
  const agencySelectedValidation = nonEmptyValidation(allAgencies);
  const confirmEnabled = agencySelectedValidation === null;

  useEffect(() => {
    setConfirmEnabled(confirmEnabled);
  }, [confirmEnabled]);

  return (
    <div className="flex flex-col w-full gap-10">
      <div className="flex flex-col">
        <div className="flex flex-col items-stretch mb-7">
          <div className="mb-2">
            Select the agency(s)/organization(s) for which you are requesting the training
          </div>
          <InputDropdown
            light
            value={selectedAgencies}
            onChange={e => updateAgenciesDropdown(e.target.value as unknown as string[])}
            items={agencyOptions as InputDropdownItem[]}
            name="agency-select"
            placeholder="Select one or more agencies"
            multiple
            isSearchable
          />
          <FieldValidationWarning light result={agencySelectedValidation} />
        </div>
        <div className="mb-2">
          In case any of the agencies/organizations is missing from the list, please enter them
          below
        </div>
        <InputText
          light
          value={trainingRequest.other_agencies ?? ''}
          onChange={e => updateTrainingRequest('other_agencies', e.target.value)}
          name="other_agencies-input"
          placeholder="Enter missing agencies"
        />
      </div>
    </div>
  );
};

export default AgencyEdit;
