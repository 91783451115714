import React, { FC } from 'react';
import { Checkmark, Exclamation, ExclamationCircle } from '@/assets/icons';

interface AlertProps {
  alertType: 'success' | 'error' | 'warning';
  titleText: string;
  descriptionTextTitle?: string;
  descriptionContent?: React.ReactNode;
  instructionText?: string;
  buttons?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  textSize?: 'sm' | 'base' | 'lg';
  width?: string;
}

const Alert: FC<AlertProps> = ({
  alertType = 'success',
  titleText,
  descriptionTextTitle,
  instructionText,
  buttons,
  onCancel,
  onConfirm,
  descriptionContent,
  textSize = 'base',
  width,
}) => {
  const iconMap = {
    success: <Checkmark />,
    error: <ExclamationCircle />,
    warning: <Exclamation />,
  };

  const backgroundColors = {
    success: ['bg-gossip', 'bg-gray-400', 'bg-green-500'],
    error: ['bg-cinderella', 'bg-gray-400', 'bg-red-600'],
    warning: ['bg-double-pearl-lusta', 'bg-gray-400', 'bg-amber-500'],
  };

  const textSizeClasses = {
    sm: 'text-sm',
    base: 'text-base',
    lg: 'text-lg',
  };

  return (
    <div className={`w-[${width}] rounded-sm p-2 pl-4 pr-4 ${backgroundColors[alertType][0]}`}>
      <div className="flex items-center mb-2">
        <span className="mr-2 text-2xl">{iconMap[alertType]}</span>
        <span className={`font-bold ${textSizeClasses[textSize]} text-black`}>{titleText}</span>
      </div>
      {descriptionTextTitle && (
        <div className="text-sm mb-4 text-black pl-8">{descriptionTextTitle}</div>
      )}
      {descriptionContent && <div className="text-sm mb-2 text-black">{descriptionContent}</div>}
      {instructionText && (
        <div className="text-sm mb-3 w-auto text-black pl-8">{instructionText}</div>
      )}
      {buttons && (
        <div className="mt-3 mb-3 flex justify-between">
          <button
            className={`w-[100px] h-[40px] rounded-md ${backgroundColors[alertType][1]}`}
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className={`w-[100px] h-[40px] rounded-md ${backgroundColors[alertType][2]}`}
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      )}
    </div>
  );
};

export default Alert;
